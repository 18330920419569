const Noodl = require('@noodl/noodl-sdk');



function TableComponent(props) {
	let style = {
		position: "relative",
		borderSpacing: (props.onlyVertical ? "0 ":"")+props.borderSpacingV+"px",
		borderCollapse: props.borderCollapse,
		backgroundColor: props.backgroundColor
	}
	return <table className={props.className} style={style} onClick={props.onClick}>{props.children}</table>
}

const TableNode = Noodl.defineReactNode({
	name: 'Table',
	category: 'Table',
	getReactComponent() {
		return TableComponent;
	},
	inputProps: {
		className: {type: 'string', default: 'table', displayName: 'Class name', group: 'Config'},
		backgroundColor: {type: "color", displayName: "Background Color"},
		onlyVertical:{type: 'boolean', default: true, displayName: 'Spacing Vertical Only', group: 'Style'},
		borderSpacingV:{type: 'string', default: '0', displayName: 'Border Spacing Vertical', group: 'Style'},
		borderCollapse:{
			type:{
				name: 'enum', 
				enums: [
					{label: "Collapse", value:"collapse"},
					{label: "Separate", value:"separate"}
				],
			},
			default: "separate", 
			displayName: 'Border collapse',
			group: 'Style'}
	},
	outputProps: {
		onClick: {type: 'signal', displayName: 'Click'}
	}
})



function TableHeadComponent(props) {
	let style = {position: "relative", backgroundColor:props.backgroundColor}
	return <thead className={props.className} style={style} onClick={props.onClick}>{props.children}</thead>
}

const TableHeadNode = Noodl.defineReactNode({
	name: 'Table Head',
	category: 'Table',
	getReactComponent() {
		return TableHeadComponent;
	},
	inputProps: {
		className: {type: 'string', default: 'table-head', displayName: 'Class name', group: 'Config'},
		backgroundColor: {type: "color", displayName: "Background Color"}
	},
	outputProps: {
		onClick: {type: 'signal', displayName: 'Click'}
	}
})




function TableBodyComponent(props) {
	let style = {position: "relative", backgroundColor:props.backgroundColor}
	return <tbody className={props.className} style={style} onClick={props.onClick}>{props.children}</tbody>
}

const TableBodyNode = Noodl.defineReactNode({
	name: 'Table Body',
	category: 'Table',
	getReactComponent() {
		return TableBodyComponent;
	},
	inputProps: {
		className: {type: 'string', default: 'table-body', displayName: 'Class name', group: 'Config'},
		backgroundColor: {type: "color", displayName: "Background Color"}
	},
	outputProps: {
		onClick: {type: 'signal', displayName: 'Click'}
	}
})



function TableRowComponent(props) {
	let style = {position: "relative", backgroundColor:props.backgroundColor}
	return <tr className={props.className} 
			   style={style} 
			   onClick={props.onClick} 
			   onMouseEnter={props.hoverStart}
			   onMouseLeave={props.hoverEnd}
			>
			   {props.children}
			</tr>
}

const TableRowNode = Noodl.defineReactNode({
	name: 'Table Row',
	category: 'Table',
	getReactComponent() {
		return TableRowComponent;
	},
	inputProps: {
		className: {type: 'string', default: 'table-row', displayName: 'Class name', group: 'Config'},
		backgroundColor: {type: "color", displayName: "Background Color"},
	},
	outputProps: {
		onClick: {type: 'signal', displayName: 'Click'},
		hoverStart: {type: 'signal', displayName: 'Hover Start'},
		hoverEnd: {type: 'signal', displayName: 'Hover End'},
	}
})




function TableCellComponent(props) {
	let style = {
		position: "relative",
		backgroundColor: props.backgroundColor,
		paddingTop: props.paddingTop+"px",
		paddingRight: props.paddingRight+"px",
		paddingBottom: props.paddingBottom+"px",
		paddingLeft: props.paddingLeft+"px"
	}

	let el = <td className={props.className} style={style} onClick={props.onClick}>{props.children}</td>;

	if(props.cellType === "th"){
		el = <th className={props.className} style={style} onClick={props.onClick}>{props.children}</th>;
	}

	return el
}


const TableCellNode = Noodl.defineReactNode({
	name: 'Table Cell',
	category: 'Table',
	getReactComponent() {
		return TableCellComponent;
	},
	inputProps: {
		cellType: {
            group: 'Element',
            displayName: 'Cell type',
            type: {
                name: 'enum',
                enums: [{label: 'Data', value: 'td'}, {label: 'Header', value: 'th'}]
			},
			default: 'td'
		},
		paddingTop:{type: "number", default: 0, displayName: "Padding Top"},
		paddingLeft:{type: "number", default: 0, displayName: "Padding Left"},
		paddingBottom:{type: "number", default: 0, displayName: "Padding Bottom"},
		paddingRight:{type: "number", default: 0, displayName: "Padding Right"},
		backgroundColor: {type: "color", displayName: "Background Color"},
		className: {type: 'string', default: 'table-header-cell', displayName: 'Class name', group: 'Config'}
	},
	outputProps: {
		onClick: {type: 'signal', displayName: 'Click'}
	}
})





Noodl.defineModule({
    reactNodes: [
		TableNode,
		TableHeadNode,
		TableBodyNode,
		TableRowNode,
		TableCellNode
    ],
    nodes:[
    ],
    setup() {


    }
});